(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

var color = 'color:#008b8b';
var background = 'background-color:#fff8dc';
var whiteSpace = 'white-space:break-spaces';
var display = 'display:flow';
var padding = 'padding: 0 30px';
var textAlign = 'text-align: center';
var styles = [color, background, whiteSpace, display, padding, textAlign];
export var logCss = styles.join(';');
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(color, "color", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/styles.js");
  reactHotLoader.register(background, "background", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/styles.js");
  reactHotLoader.register(whiteSpace, "whiteSpace", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/styles.js");
  reactHotLoader.register(display, "display", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/styles.js");
  reactHotLoader.register(padding, "padding", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/styles.js");
  reactHotLoader.register(textAlign, "textAlign", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/styles.js");
  reactHotLoader.register(styles, "styles", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/styles.js");
  reactHotLoader.register(logCss, "logCss", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/core/tracker/styles.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();