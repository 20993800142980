(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

window.dataLayer = window.dataLayer || [];
window.dataLayer = window.dataLayer || [];

function gtag() {
  // eslint-disable-next-line no-undef, prefer-rest-params
  dataLayer.push(arguments);
}

window.gtag = gtag;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(gtag, "gtag", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/js/globals.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();