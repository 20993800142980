(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

import ShoppingCart from '@ampush/centaurus/shopping-cart';
var shopifyConfig = {
  token: '',
  shopName: 'https://andieswim.com'
};
var shopifyCart = new ShoppingCart({
  shopifyConfig: shopifyConfig
});
var _default = shopifyCart;
export default _default;
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(shopifyConfig, "shopifyConfig", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/variants/products/js/shopify-init.js");
  reactHotLoader.register(shopifyCart, "shopifyCart", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/variants/products/js/shopify-init.js");
  reactHotLoader.register(_default, "default", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/variants/products/js/shopify-init.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();