(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

import loadTrackAsync from "../../../../core/tracker/load-async"; // eslint-disable-next-line no-unused-vars

import { gtag } from "../../../../js/globals";
import { hotjarPixel } from "../../../../js/hotjar";
import shopifyCart from "../shopify-init";
import "../../scss/products-main.scss";

function AndieProducts() {
  loadTrackAsync();
  hotjarPixel();
  window.shopifyCart = shopifyCart;
}

window.onload = new AndieProducts();
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(AndieProducts, "AndieProducts", "/codebuild/output/src604834662/src/github.com/ampush/wear.andieswim.com/src/variants/products/js/amalfi-flat-navy/index.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();